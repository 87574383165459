import React, { useState } from "react"
import Navbar from "react-bootstrap/Navbar"
import Nav from "react-bootstrap/Nav"
import { Link } from "gatsby"
import logo from "../../images/logo.svg"

const Header = () => {
  const [openToggler, setOpenToggler] = useState(false)

  return (
    <Navbar expand="lg" fixed="top">
      <div className="container">
        <Link className="navbar-brand" to="/">
          <img src={logo} />
        </Link>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          onClick={() => setOpenToggler(!openToggler)}
        >
          <div className="navbar-toggler navbar-toggler-right">
            {/* <!-- <i className="fa fa-bars"></i>--> */}
            <div
              className={openToggler ? "menuBars clickMenuBars" : "menuBars"}
            >
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </Navbar.Toggle>

        <Navbar.Collapse id="responsive-navbar-nav">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <Link className="nav-link" to="/">
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/platform">
                Platform
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/pricing">
                Pricing
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link ultimo" to="/contact">
                Contact Us
              </Link>
            </li>

            <li className="nav-item mt-3 mt-lg-0">
              <Link className="btn btn-primary" to="/signup">
                START FREE TRIAL
              </Link>
            </li>
            <li
              className="nav-item mt-3 mt-lg-0"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "2.5rem",
                height: "3.5rem",
              }}
            >
              <a
                className="btn btn-primary signin"
                href={process.env.GATSBY_ODS_URL}
              >
                <i
                  className="las la-sign-in-alt"
                  style={{
                    fontSize: "2rem",
                  }}
                />
              </a>
            </li>
          </ul>
        </Navbar.Collapse>
      </div>
    </Navbar>
  )
}

export default Header
