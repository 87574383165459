// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-article-js": () => import("./../../src/pages/article.js" /* webpackChunkName: "component---src-pages-article-js" */),
  "component---src-pages-blog-js": () => import("./../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-platform-js": () => import("./../../src/pages/platform.js" /* webpackChunkName: "component---src-pages-platform-js" */),
  "component---src-pages-pricing-js": () => import("./../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-signup-js": () => import("./../../src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-pages-usecase-js": () => import("./../../src/pages/usecase.js" /* webpackChunkName: "component---src-pages-usecase-js" */)
}

