import React from "react"
import { Link } from "gatsby"

const Footer = () => {
  return (
    <footer>
      <div className="container-fluid">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <Link to="/">Home</Link> |<Link to="/platform">Platform</Link> |
              <Link to="/pricing">Pricing</Link> |
              <Link to="/contact">Contact</Link>
            </div>
            <div className="col-md-6 col-sm-12 text-right">
              © 2019 OpenDatastage
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
