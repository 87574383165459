import React from "react"
import Navbar from "../Navbar/Navbar"
import Footer from "../Footer/Footer"
import "typeface-raleway"
import "../../styles/global.scss"

const Layout = ({ children }) => {
  return (
    <div>
      <Navbar />
      {children}
      <Footer />
    </div>
  )
}

export default Layout
